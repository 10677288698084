<template>
    <v-dialog v-model="addDialog" persistent transition="scale-transition" max-width="1200px">
        <v-card class="elevation-1">
            <ValidationObserver ref="obsUnidad" v-slot="{ invalid, validated } ">
                <v-form name="form" @submit.prevent="store">
                    <v-toolbar dark text color="primary">
                        <v-btn icon dark @click="handleModal">
                            <v-icon>fas fa-times-circle</v-icon>
                        </v-btn>
                        <v-toolbar-title>Registrar nueva unidad</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn class="primary" type="submit" :disabled="invalid || !validated">
                                <v-icon class="ma-2" dense medium left>fas fa-save</v-icon> Guardar
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <ValidationProvider rules="required|min:2|max:5"  v-slot="{ errors, valid}">
                                    <v-text-field label="Clave de la unidad*" prepend-icon="fas fa-key"
                                    v-model="tipoUnidad.clave" required :counter="5" :error-messages="errors" :success="valid">
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <ValidationProvider rules="required" v-slot="{ errors, valid}">
                                    <v-text-field label="Nombre de la unidad*" prepend-icon="fas fa-balance-scale"
                                    v-model="tipoUnidad.nombre" required :counter="100" :error-messages="errors" :success="valid">
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-form>
            </ValidationObserver>
        </v-card>
    </v-dialog>  
</template>
<script>
import tipoUnidadService from "@/service/catalogo/tipoUnidad/tipoUnidadService";
export default {
    name: 'NuevaUnidad',
    props: {
        addDialog: { type: Boolean, default: false },
        initTable: { type: Function },
        handleModal: { type: Function }
    },
    data() {
        return {
            tipoUnidad: {
                clave: '',
                nombre: ''
            }
        }
    },
    methods: {
        store(){
            this.$refs.obsUnidad.validate().then( isValid => {
                if( isValid ){
                    tipoUnidadService.create( this.tipoUnidad ).then(_ => {
                        this.$refs.obsUnidad.reset();
                        this.initTable();
                        this.handleModal();
                        this.$swal.fire({ icon:'success', title: 'Catalogo de unidad',
                            text: 'La unidad se guardó correctamente', showConfirmButton: false, timer: 1500
                        });
                    }).catch( error => {
                        this.$swal.fire({ icon:'error', title: 'Catalogo de unidad',
                            html: error.response.data, showConfirmButton: false, timer: 1500
                        });
                    });
                }
            })
        }

    }
}
</script>