<template>
    <v-dialog v-model="editDialog" persistent transition="scale-transition" max-width="1200px">
        <v-card class="elevation-1">
            <ValidationObserver ref="obsEditUnidad" v-slot="{invalid, validated}" :immediate="true">
                <v-form name="form" @submit.prevent="update">
                    <v-toolbar dark text color="primary">
                        <v-btn icon dark @click="handleModal">
                            <v-icon>fas fa-times-circle</v-icon>
                        </v-btn>
                        <v-toolbar-title>Editar unidad</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn class="primary" type="submit" :disabled="invalid || !validated">
                                <v-icon class="ma-2" dense large left>fas fa-save</v-icon> Guardar
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <ValidationProvider rules="required|min:2|max:5" v-slot="{ errors, valid }" :immediate="true">
                                    <v-text-field label="Clave de la  unidad*" prepend-icon="fas fa-key"
                                    v-model="tipoUnidad.clave" required :counter="5" :error-messages="errors" :success="valid">
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                    <v-text-field label="Nombre de la  unidad*" prepend-icon="fas fa-balance-scale"
                                    v-model="tipoUnidad.nombre" required :counter="10" :error-messages="errors" :success="valid">
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-form>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>
<script>
import TipoUnidadService from '@/service/catalogo/tipoUnidad/tipoUnidadService';
export default {
    name: 'EditarUnidad',
    props: {
        editDialog: { type: Boolean, default: false },
        idUnidad: { type: Number, required: true, default: 0 },
        initTable: { type: Function },
        handleModal: { type: Function }
    },
    created() {
        this.show(this.idUnidad);
    },
    data() {
        return {
            tipoUnidad: []
        }
    },
    methods: {
        show(idUnidad){
            TipoUnidadService.show(idUnidad).then(result => {
                this.tipoUnidad = result.data;
            });
        },
        update(){
            this.$refs.obsEditUnidad.validate().then( isValid  => {
                if( isValid ){
                    TipoUnidadService.update(this.idUnidad, this.tipoUnidad).then(response => {
                        if (response.status == 200 ){
                            this.handleModal();
                            this.$refs.obsEditUnidad.reset();
                            this.initTable();
                            this.$swal.fire({ icon: 'success', title: 'Unidad actualizada', text: 'La unidad se actualizó correctamente', showConfirmButton: false, timer: 2000 });
                        }
                    });
                }
            }).catch(error => {
                this.$swal.fire({ icon: 'error', title: 'No se pudo actualizar la unidad', html: error.response.data, showConfirmButton: false, timer: 2000 });
            });
        }
    }
}
</script>