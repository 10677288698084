<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="primary" @click="addDialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nueva unidad
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-col cols="12" md="8" sm="4" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search" label="Buscar unidad" single-inline hide-details @keydown.enter="searchUnidad">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                    </v-col>
                </v-card-title>

                <v-data-table :headers="headers" :items="unidades" class="elevation-1"
                :loading="loading" :options.sync="options"
                :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ] }"
                :server-items-length="totalUnidad">
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn icon small @click="show(item)" v-if="!item.eliminado">
                            <v-icon small>fas fa-edit</v-icon>
                        </v-btn>
                        <v-btn icon small @click="remove(item)" v-if="!item.eliminado">
                            <v-icon small>fas fa-trash-alt</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:[`item.eliminado`]="{ item }">
                        <v-chip :color="colorStatus(item.eliminado)" label dark x-small text-color="white"
                            v-text="item.eliminado ? 'Cancelado' : 'Activo'">
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
        <NuevaUnidad :addDialog.sync="addDialog" :initTable="initTable" :handleModal="handleModal" v-if="addDialog">
        </NuevaUnidad>
        <EditarUnidad :editDialog.sync="editDialog" :initTable="initTable" :idUnidad.sync="idUnidad"
        :handleModal="handleModalEdit" v-if="editDialog"/>
    </v-row>
</template>
<script>
import tipoUnidadService from '@/service/catalogo/tipoUnidad/tipoUnidadService'
import NuevaUnidad from './NuevaUnidad.vue'
import EditarUnidad from './EditarUnidad.vue'
import { getParamsPagination } from '@/utils/pagination'
export default {
    name: "ListaUnidad",
    components: { NuevaUnidad, EditarUnidad },
    created() {
        this.initTable()
    },
    data() {
        return {
            unidades: [],
            options: {},
            totalUnidad: 0,
            search: '',
            loading: false,
            addDialog: false,
            editDialog: false,
            idUnidad: 0,
            headers: [
                { text: 'Acciones', value: 'actions', sortable: false },
                { text: 'Clave', value: 'clave', sortable: true },
                { text: 'Nombre', value: 'nombre', sortable: true },
                { text: 'ID', align: ' d-none', sortable: false, value: 'id', visible: false },
                { text: 'Estatus', value: 'eliminado' }
            ]
        }
    },
    computed: {
        filteredUnidades() {
            return this.unidades.filter(item => !item.eliminado);
        },
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initTable(val);
                }
            }, deep: true
        }
    },
    methods: {
        searchUnidad() {
            this.options.page = 1
            this.initTable(this.options);
        },
        async initTable( options = {} ) {
            this.loading = true
            const params = getParamsPagination({ options, search: this.search})
            await tipoUnidadService.listaTipoUnidad(params).then(response => {
                const { rows, totalElements } = response.data;
                this.unidades = rows
                this.totalUnidad = totalElements
            }).catch(error => {
                this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error al obtener los datos', html: error.response.data, showConfirmButton: false, timer: 1500 })
            }).finally(() => {
                this.loading = false
            })
        },
        show({ id}) {
            this.editDialog = true
            this.idUnidad = id;
        },
        remove(item) {
            this.$swal({
                title: '¿Estás seguro?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    tipoUnidadService.delete(item.id).then(res => {
                        if (res.status == 200) {
                            this.$swal.fire({ icon: 'success', title: 'Unidad eliminada correctamente', showConfirmButton: false, timer: 1500 })
                            this.initTable();
                        }
                    }).catch(err => {
                        this.$swal.fire({
                            title: 'Cancelado', html: err.response.data,
                            icon: 'error', timer: 2000
                        });
                    })
                } else {
                    this.$swal.fire({
                        title: 'Cancelado', text: 'Solicitud cancelado',
                        showConfirmButton: false, icon: 'info', timer: 2000
                    });
                }
            }).catch(() => {
                this.$swal.fire({
                    title: 'Cancelado', text: 'Proceso de validacion rechazado',
                    showConfirmButton: false, icon: 'info', timer: 2000
                });
            });
        },
        colorStatus(status) { return status ? "red" : "green"; },
        handleModal() { this.addDialog = !this.addDialog },
        handleModalEdit() { this.editDialog = !this.editDialog }
    }
}
</script>