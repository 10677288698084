import { http, authHeader } from '@/http-commons';

class TipoUnidadService {

    create( data ){
        return http.post('api/v1/tipoUnidad/create', data, {headers : authHeader() });
    }
    show(id){
        return http.get(`api/v1/tipoUnidad/show/${id}`, {headers : authHeader() });
    }
    update( idUnidad, data ){
        return http.post(`api/v1/tipoUnidad/update/${idUnidad}`, data, {headers : authHeader() });
    }
    delete( id ){
        return http.delete(`api/v1/tipoUnidad/delete/${id}`, {headers : authHeader() });
    }
    getAllUnidad(){
        return http.get('api/v1/tipoUnidad/getAll', { headers : authHeader() });
    }
    listaTipoUnidad(params){
        return http.get('api/v1/tipoUnidad/table', { params, headers : authHeader() });
    }
}
export default new TipoUnidadService();